import { useParams, useNavigate, Link } from "react-router-dom";

import Demo from "../components/Demo";

import { useEffect, useState } from "react";
import API from "../API";
import { formatDate, formatUnit, num2str, waitOWOrder } from "../lib/utils";
import Countdown from "../components/Countdown";
import { getUserInfo } from "../stores/userStore";
import { useSolPrice } from "../context/solPriceContext";

export default function Home() {
  let navigate = useNavigate();
  let params = useParams();
  let [queryStr, setQueryStr] = useState("");

  const solPrice = useSolPrice();

  let userInfo = getUserInfo();
  let isLogin = userInfo.userToken;
  /*
    setTokenStatus("BOUNDING")
    setTokenStatus("LAUNCHED")
  */
  let [tokenStatus, setTokenStatus] = useState("BOUNDING");

  let [tokenList, setTokenList] = useState<any>([]);

  /*
    rushId	long	 round Id 1
    startTme	date	  2024-09/08 18:02:26
    endTime	date	  2024-09/08 18:02:26
    pricePool	string	 pool string1
    rushTraderInfoRespList	[Api_MEME_RushTraderInfoResp]
  */
  let [rushTrader, setRushTrader] = useState<any>({
    rushTraderInfoRespList: [],
  });

  const handleKeyDown = function (event: any) {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = async function () {
    let res = await API.meme.pageTokenWithSearch({
      keyword: queryStr.trim() || "",
      tokenStatus: tokenStatus,
      page: {
        offset: 0,
        limit: 6,
      },
      tokenOrderByType: "current_market_cap",
      orderBy: "desc",
    });
    // console.log(res);
    setTokenList(res.data.value || []);
  };

  /*
      address
      nickName
      avatar
      score
      expectAward
      rank
  */
  let [user48HoursRushInfo, setUser48HoursRushInfo] = useState({
    address: "",
    nickName: "",
    avatar: "",
    score: 0,
    expectAward: "",
    rank: 0,
  });

  const get48HoursRushTraderList = async function () {
    let res = await API.meme.get48HoursRushTraderList();
    // console.log("get48HoursRushTraderList", res);
    // debugger;
    if (res.data) {
      setRushTrader(res.data || {});

      if (isLogin && res.data.rushId) {
        let res1 = await API.meme.getUser48HoursRushInfo({
          rushId: res.data.rushId,
        });
        if (res1.data) {
          setUser48HoursRushInfo(res1.data);
        }
      }
    }
  };

  // CTO funding
  let [CTOFundingList, setCTOFundingList] = useState({
    fundingId: 0, //id
    totalVotes: 0, // 本轮总投票数
    isOverHalf: 0, //
    canProposal: 0, //
    startTime: 0, // POSIX time
    endTime: 0, // POSIx time
    pricePool: "", // 奖金池
    lackVotes: 0, // 距投票过半缺少的投票数
    fundingEnd: 0, // 投资结束时间
    status: 0, // 本轮状态 0:未开始 1:投票中 2:投资中 3:已结束
    fundingTokenInfoRespList: [
      { address: "", name: "", ticker: "", image: "", votes: 0 },
    ], //
  });
  const pageCTOFundingList = async function () {
    let res = await API.meme.pageCTOFundingList({
      page: {
        offset: 0,
        limit: 4,
      },
    });

    if (res.data) {
      setCTOFundingList(res.data);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [tokenStatus]);

  useEffect(() => {
    get48HoursRushTraderList();
    pageCTOFundingList();
  }, []);

  /*
   ** tabs**
   *
   */
  const [isShow, setIsShow] = useState(1),
    baseClass =
      "h-10 cursor-pointer rounded-lg px-3 text-base leading-10 bg-white bg-opacity-10 text-white",
    hoverClass =
      "h-10 cursor-pointer rounded-lg px-3 text-base leading-10 bg-gradient-to-r from-[#00FF80] to-[#00FFE2] font-bold text-black";

  return (
    <>
      {!waitOWOrder && (
        <div className="flex justify-center pt-20">
          <h1 className="ct-font-silk ct-text-gt1 text-7xl">
            CTO fund is coming soon
          </h1>
        </div>
      )}

      {waitOWOrder && (
        // 这是最外层结构
        <>
          <div className="flex flex-col items-center gap-2">
            <h1 className="ct-font-silk ct-text-gt1 text-[2.5rem]">
              Call Of MEME
            </h1>
            <h2 className="ct-text-gt2 text-2xl font-bold">
              $vote for MEME Coins to get CTO FUND
            </h2>
            {/* <button
          onClick={() => {
            navigate("/create");
          }}
          className="btn rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
        >
          Create A New Coin
        </button> */}
          </div>
          {/* 48hoursrush & CTO fund -Section */}
          <div className="mx-auto mt-6 max-w-[1600px] rounded-tl-lg bg-[#17181B]">
            {/* CTO fund -Block */}
            {CTOFundingList.status === 1 && (
              <p className="inline-block rounded-br-lg rounded-tl-lg bg-white bg-opacity-10 px-6 py-3 text-lg font-bold text-white">
                pumping will start after{" "}
                {formatDate(CTOFundingList.endTime, "MM/dd/yyyy hh:mm:ss")} UTC
              </p>
            )}
            <div className="mx-auto min-h-[700px] max-w-[1200px]">
              <div className="mb-9 pt-6 text-center">
                <h3 className="ct-font-silk ct-text-gt4 text-[2.5rem] text-[#FF8700]">
                  CTO FUND : ROUND {CTOFundingList.fundingId}
                </h3>
                <p>
                  Use $vote to vote, the winner will receive the CTO FUND and
                  get up to 120x return
                </p>
              </div>
              <div className="mb-9 grid grid-flow-col text-center">
                <div className="">
                  <div className="mb-4 flex items-center justify-center gap-2 text-lg">
                    {CTOFundingList.status === 1 && (
                      <>
                        The voting phase will end in
                        <span
                          className="tooltip tooltip-right cursor-pointer text-left before:whitespace-pre-line"
                          data-tip={`Each round of the CTO FUND consists of two phases: the voting phase lasts 25 days, followed by a 5-day pumping phase. \n\n Funds from the prize pool will be used to purchase the winning tokens in multiple batches, with the corresponding transaction records published afterward.`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            className="opacity-50"
                          >
                            <path
                              d="M8.75 0.25C3.92188 0.25 0 4.17188 0 9C0 13.8281 3.92188 17.75 8.75 17.75C13.5781 17.75 17.5 13.8281 17.5 9C17.5 4.17188 13.5781 0.25 8.75 0.25ZM9.375 14H8.125V12.75H9.375V14ZM9.59375 10.0312L9.48438 10.125C9.42187 10.1719 9.375 10.2812 9.375 10.375V11.5156H8.125V10.375C8.125 9.89063 8.34375 9.4375 8.70312 9.14062L8.8125 9.04688C9.90625 8.17188 10.4375 7.71875 10.4375 6.9375C10.4375 6 9.6875 5.25 8.75 5.25C7.78125 5.25 7.0625 5.96875 7.0625 6.9375H5.8125C5.8125 5.29688 7.10938 4 8.75 4C10.375 4 11.6875 5.3125 11.6875 6.9375C11.6875 8.35938 10.7188 9.14062 9.59375 10.0312Z"
                              fill="#B8B8B8"
                            />
                          </svg>
                        </span>
                      </>
                    )}
                    {CTOFundingList.status === 2 && (
                      <>The pumping phase will end in</>
                    )}
                  </div>
                  <div className="text-3xl font-bold tracking-widest text-[#FFFFFF]">
                    <Countdown
                      endTime={
                        CTOFundingList.status === 1
                          ? CTOFundingList.endTime
                          : CTOFundingList.fundingEnd
                      }
                    >
                      {({ expired, formatTime }) => (
                        <>
                          <span>{formatTime.days}</span>D
                          <span className="mx-5">:</span>
                          <span>{formatTime.hours}</span>H
                          <span className="mx-5">:</span>
                          <span>{formatTime.minutes}</span>M
                          <span className="mx-5">:</span>
                          <span>{formatTime.seconds}</span>S
                        </>
                      )}
                    </Countdown>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <h4 className="mb-9 text-3xl font-bold text-[#FF8700]">
                  The current CTO FUND: $
                  {CTOFundingList.lackVotes > 0
                    ? num2str(Number(CTOFundingList.pricePool) / 2)
                    : num2str(Number(CTOFundingList.pricePool))}
                </h4>
                <p className="text-white">
                  The current vote is {num2str(CTOFundingList.totalVotes)} ,{" "}
                  {CTOFundingList.lackVotes > 0 && (
                    <>
                      with {num2str(CTOFundingList.lackVotes)} votes needed to
                      jump to{" "}
                      <span className="text-[#28E1A6]">
                        next pool: ${num2str(Number(CTOFundingList.pricePool))}
                      </span>
                    </>
                  )}
                  {/* 业务需求：以lackVotes为判断标准，但目前未知lackVotes是否会返回<= 0的具体情况  */}
                  {CTOFundingList.lackVotes <= 0 && (
                    <>
                      which has reached the{" "}
                      <span className="text-[#28E1A6]">
                        maximum pool of: $
                        {num2str(Number(CTOFundingList.pricePool))}
                      </span>
                    </>
                  )}
                </p>
              </div>
              <div className="mx-20 mt-10">
                <table className="mb-10 w-full text-sm">
                  <thead>
                    <tr className="text-[#8E8E92]">
                      <th className="p-0 font-normal">
                        <div className="mb-2 border-b border-[#27282B] bg-[#151717]">
                          # Rank
                        </div>
                      </th>
                      <th className="p-0 font-normal">
                        <div className="mb-2 border-b border-[#27282B] bg-[#151717] pl-10 text-left">
                          Coin
                        </div>
                      </th>
                      <th className="p-0 font-normal">
                        <div className="mb-2 border-b border-[#27282B] bg-[#151717] text-left">
                          Votes
                        </div>
                      </th>
                      {CTOFundingList.status === 1 && (
                        <th className="p-0 font-normal">
                          <div className="mb-2 border-b border-[#27282B] bg-[#151717]">
                            &nbsp;
                          </div>
                        </th>
                      )}
                      <th className="p-0 font-normal">
                        <div className="mb-2 border-b border-[#27282B] bg-[#151717]">
                          &nbsp;
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[#F4F5F7]">
                    {CTOFundingList.fundingTokenInfoRespList.map((_, item) => (
                      <tr
                        className={`h-10 ${item === 0 && "bg-[rgb(255_135_0)] bg-opacity-30"}`}
                        key={item}
                      >
                        <td className="text-center">{item + 1}</td>
                        <td className="pl-12">
                          <Link
                            to={`/tokensdetails/${_.address}`}
                            className="flex gap-1"
                          >
                            <span className="relative">
                              {item === 0 && (
                                <img
                                  src="./coin_crown.png"
                                  alt=""
                                  className="absolute left-[-0.5625rem] top-[-0.625rem]"
                                />
                              )}
                              <img
                                src={_.image}
                                alt=""
                                className="h-5 w-5 rounded-sm object-cover"
                              />
                            </span>
                            {_.ticker}
                          </Link>
                        </td>
                        <td> {_.votes}</td>
                        {CTOFundingList.status === 1 && (
                          <td>
                            <Link
                              to={`/ctovote/${_.address}`}
                              className="text-[#FF8700]"
                            >
                              VOTE
                            </Link>
                          </td>
                        )}
                        <td>
                          <Link
                            to={`/tokensdetails/${_.address}`}
                            className="text-[#28E1A6]"
                          >
                            BUY
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="pb-10 text-center">
                  <Link
                    to="/cto"
                    className="btn h-[3.75rem] w-[11.25rem] rounded-xl bg-[#FF8700] text-2xl text-black hover:bg-[#FF8700]"
                  >
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* bounding Curve -Section */}
          <div className="mx-auto mt-8 max-w-[1600px] bg-[#17181B] p-10 pb-6">
            <div className="mb-6 grid grid-cols-[auto_auto_1fr] justify-start gap-1">
              <span
                className={tokenStatus === "BOUNDING" ? hoverClass : baseClass}
                onClick={() => setTokenStatus("BOUNDING")}
              >
                bounding Curve
              </span>
              <span
                className={tokenStatus === "LAUNCHED" ? hoverClass : baseClass}
                onClick={() => setTokenStatus("LAUNCHED")}
              >
                Launched
              </span>
              {/* <div className="ml-[20rem]">
            <div className="grid grid-cols-[1fr_auto] gap-4">
              <label className="input input-bordered flex h-10 items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="h-4 w-4 opacity-70"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd"
                  />
                </svg>
                <input
                  type="text"
                  className="grow"
                  placeholder="Search..."
                  value={queryStr}
                  onChange={(e) => setQueryStr(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </label>
              <button
                onClick={() => {
                  handleSearch();
                }}
                className="btn h-10 min-h-min rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
              >
                Search
              </button>
            </div>
          </div> */}
              <div className="flex justify-end text-2xl text-[#FFE24D]">
                <Link
                  to={`/tokens?status=${tokenStatus}`}
                  className="flex items-center gap-3"
                >
                  MORE
                  <img src="./icon_more.png" alt="" />
                </Link>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-6">
              {tokenList.slice(0, 3).map((_: any, item: any) => (
                <div
                  className={`rounded-lg border-2 bg-[#191919] p-5 ${_.kingOfHillTime ? "boder-imag relative border-[#0be570]" : "border-[rgb(255_255_255)] border-opacity-5"}`}
                  key={item}
                >
                  <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                    <div className="w-[6.25rem]">
                      <Link
                        to={`/tokensdetails/${_.address}`}
                        className="relative"
                      >
                        <img
                          src={_.image}
                          alt=""
                          className="h-[6.25rem] w-full object-cover"
                        />
                        <img
                          src="./avatar_mask.png"
                          alt=""
                          className="absolute top-0 w-full"
                        />
                      </Link>
                    </div>
                    <div className="pt-4">
                      <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                        Created by
                        <img
                          className="h-4 w-4 rounded-sm object-cover"
                          src={_.avatar || "/logo.png"}
                          alt=""
                        />
                        <Link to="/" className="text-[#BFDBFE]">
                          {_.creatorAddress.slice(0, 6)}
                        </Link>
                      </div>
                      <p className="mb-4 text-xs text-[#28E1A6]">
                        market cap:{" "}
                        {solPrice
                          ? "$" + formatUnit(_.currentMarketCap * solPrice)
                          : formatUnit(_.currentMarketCap * 1)}
                      </p>
                      <p className="text-sm text-white">
                        {_.name} [ticker: {_.ticker}]
                      </p>
                    </div>
                    <div className="grid items-center">
                      <Link
                        to={`/tokensdetails/${_.address}`}
                        className="grid gap-1 text-center text-[#0AD7A3]"
                      >
                        <i className="flex h-10 w-10 items-center justify-center rounded-full bg-[#0AD7A3]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                              fill="white"
                            />
                          </svg>
                        </i>
                        BUY
                      </Link>
                    </div>
                  </div>
                  {_.kingOfHillTime ? (
                    <img
                      src="./king_hill.png"
                      alt=""
                      className="absolute left-3 top-0 h-[2rem] w-[10.375rem] select-none"
                    />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>

            {/* {isShow === 1 ? (
          <>
            <div className="grid grid-cols-3 gap-6">
              {new Array(6).fill(0).map((_, item) => (
                <div
                  className={`rounded-lg border-2 bg-[#191919] p-5 ${item === 0 ? "boder-imag relative border-[#0be570]" : "border-[rgb(255_255_255)] border-opacity-5"}`}
                  key={item}
                >
                  <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                    <div className="w-[100px]">
                      <Link to="/" className="relative">
                        <img
                          src="./avatar_sample.jpg"
                          alt=""
                          className="w-full"
                        />
                        <img
                          src="./avatar_mask.png"
                          alt=""
                          className="absolute top-0 w-full"
                        />
                      </Link>
                    </div>
                    <div className="pt-4">
                      <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                        Created by
                        <img src="./avatar_sample_2.png" alt="" />
                        <Link to="/" className="text-[#BFDBFE]">
                          6YQcY6
                        </Link>
                      </div>
                      <p className="mb-4 text-xs text-[#28E1A6]">
                        market cap: 18.42K
                      </p>
                      <p className="text-sm text-white">
                        Hype Beast Chicken [ticker: HYPE]
                      </p>
                    </div>
                    <div className="grid items-center">
                      <Link
                        to="/tokensdetails"
                        className="grid gap-1 text-center text-[#0AD7A3]"
                      >
                        <i className="flex h-10 w-10 items-center justify-center rounded-full bg-[#0AD7A3]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                              fill="white"
                            />
                          </svg>
                        </i>
                        BUY
                      </Link>
                    </div>
                  </div>
                  {item === 0 && (
                    <img
                      src="./king_hill.png"
                      alt=""
                      className="absolute left-3 top-0 h-[32px] w-[166px] select-none"
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="mt-6 flex justify-center gap-4 text-2xl text-[#FFE24D]">
              <Link
                to="/tokens?type=1"
                className="grid grid-cols-2 items-center gap-3"
              >
                MORE
                <img src="./icon_more.png" alt="" />
              </Link>
            </div>
          </>
        ) : null}
        {isShow === 2 ? (
          <>
            <div className="grid grid-cols-3 gap-6">
              {new Array(6).fill(0).map((_, item) => (
                <div
                  className="rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5"
                  key={item}
                >
                  <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                    <div className="w-[100px]">
                      <Link to="/" className="relative">
                        <img
                          src="./avatar_sample.jpg"
                          alt=""
                          className="w-full"
                        />
                        <img
                          src="./avatar_mask.png"
                          alt=""
                          className="absolute top-0 w-full"
                        />
                      </Link>
                    </div>
                    <div className="pt-4">
                      <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                        Created by
                        <img src="./avatar_sample_2.png" alt="" />
                        <Link to="/" className="text-[#BFDBFE]">
                          6YQcY6
                        </Link>
                      </div>
                      <p className="mb-4 text-xs text-[#28E1A6]">
                        market cap: 18.42K
                      </p>
                      <p className="text-sm text-white">
                        Hype Beast Chicken [ticker: HYPE]
                      </p>
                    </div>
                    <div className="grid items-center">
                      <Link
                        to="/tokensdetails"
                        className="grid gap-1 text-center text-[#0AD7A3]"
                      >
                        <i className="flex h-10 w-10 items-center justify-center rounded-full bg-[#0AD7A3]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                              fill="white"
                            />
                          </svg>
                        </i>
                        BUY
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-6 flex justify-center gap-4 text-2xl text-[#FFE24D]">
              <Link
                to="/tokens?type=2"
                className="grid grid-cols-2 items-center gap-3"
              >
                MORE
                <img src="./icon_more.png" alt="" />
              </Link>
            </div>
          </>
        ) : null} */}
          </div>
        </>
      )}
    </>
  );
}
