import { ChainConfig } from "./setup.common";

export const devChainConfig: ChainConfig = {
  programId: "ERtymHEiAuD5tHzrAvaYriWHYfTu2hkyN9fR31X29PUM",
  voteProgramId: "GDuf65ffYqMfx1g9vZRxMF9H25fVGYEhe1ZrNLybSfJi",
  tokenIssuer: "hdJNuxkEv8Lns2Sz5ZqmkCU1pwYrb5LZjPL2AHDCcys",
  voteAdminAddress: "ArLdV2CWR44VRgp9TFJaAzeAW7qLeGJTR6V3uiQuGGMm",
  callTokenAddress: "9jqfrHSdhJLiheVdF5wxkTpgg7vNWfbUgCaLmATUNoYa",
  voteTokenAddress: "8qMNtqtQyDnbSwZUTniLd2zVqWBBXoDedANDGRSZiq7b",
  merkleDistributorProgramId: "HVdsBzUkcdBjkT5KLEekPZxdYmJ6goiMc2TYXWk8cubK",
  heliusRPCEndpoint: "https://devnet.helius-rpc.com/?api-key=d4b066f7-15d5-45ad-ac46-cde3eb26028a",
  congressCandyMachineId: "H2Q1SZPtrcEnkYPpmxonscGspD3Jf8XvF6HRgMzqhUFP",
  congressCollectionAddress: "2hPK9ZycdDpVaA3HkU3RJLdHubxdLP9Q87AMQ4XTYfsB",
  goldCandyMachineId: "54GzEVeWXUQG2jfWooK3roZqNf8r6HtD542rw439E4hQ",
  goldCollectionAddress: "6xpt187mtHYa7aAPVdc6qMWp2v5AYUMMBW1sm1AwdkZk",
  silverCandyMachineId: "ECsVAyH8s5xeCH7zkpk5G8KAKRQRNriM28NPWuBNhcDp",
  silverCollectionAddress: "m8JfuVYyWA3AMs3wCNuqBQTgRDb1oeQvKaMXwuHSoVG",
  treasuryAddress: "GTneE6G6kguaM81zYRok9P8v7pDxDNn7CNpPNy3YeFXE",
}