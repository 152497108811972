let env = process.env.NODE_ENV;

let baseUrl = "";

if (env === "development") {
  // baseUrl = "http://45.12.131.106";
  // baseUrl = "https://trade.gamehub.net.cn";
  baseUrl = "https://call.meme";
} else if (env === "production") {
  baseUrl = "";
}

export default {
  baseUrl,
  apiUrl: baseUrl + "/apigw/m.api",
  //   fileUrl: baseUrl + '/apigw/file.api',
  appId: 1, // project id
};
