import { useParams, useNavigate, Link } from "react-router-dom";
import API from "../API";
import { useEffect, useRef, useState } from "react";
import { useMint } from "../hooks/useMint";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import {
  GOLD_CANDY_MACHINE_ID_STR,
  SILVER_CANDY_MACHINE_ID_STR,
} from "../anchor/setup";
import { Metaplex } from "@metaplex-foundation/js";
import { PublicKey } from "@solana/web3.js";

export default function Nft() {
  const [mint, loadingMap] = useMint();

  const wallet = useWallet();

  const { connection } = useConnection();
  const metaplex = new Metaplex(connection);
  const goldCandyMachineId = new PublicKey(GOLD_CANDY_MACHINE_ID_STR);
  const silverCandyMachineId = new PublicKey(SILVER_CANDY_MACHINE_ID_STR);

  const maxMintCount = 5;
  const [mintAmountA, setMintAmountA] = useState(1);
  const [mintAmountB, setMintAmountB] = useState(1);

  const [goldMinted, setGoldMinted] = useState(0);
  const [silverMinted, setSilverMinted] = useState(0);

  async function getMintedCount() {
    const goldCandyMachine = await metaplex.candyMachines().findByAddress({
      address: goldCandyMachineId,
    });
    setGoldMinted(goldCandyMachine.itemsMinted.toNumber());

    const silverCandyMachine = await metaplex.candyMachines().findByAddress({
      address: silverCandyMachineId,
    });
    setSilverMinted(silverCandyMachine.itemsMinted.toNumber());
  }

  const navigate = useNavigate();

  useEffect(() => {
    getMintedCount();

    // NFT页面不需要，预防URL直接打开，兜底跳转首页
    navigate("/");
  }, []);

  return (
    <>
      <div className="mx-auto max-w-[1000px]">
        <div className="mb-6 flex flex-col items-center">
          <div className="mb-6 flex items-center justify-around">
            <h3 className="ct-font-silk ct-text-gt1 text-[2.5rem]">NFT</h3>
            <a
              href="/rarenft"
              className="btn ml-3 h-10 min-h-min w-full rounded-none bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
            >
              Get Congress Member
            </a>
          </div>
          <p className="text-xl text-[#C1C1C1]">
            All NFT sales revenue will be allocated to the CTO Fund and
            subsequently used for market-making activities.
          </p>
          <p className="text-xl text-[#C1C1C1]">
            Holding NFT can obtain governance token $call airdrops and other
            benefits
          </p>
        </div>
        <ul className="grid grid-cols-2 gap-10">
          {new Array(2).fill(0).map((_, item, index) => (
            <li className="bg-black bg-opacity-50" key={item}>
              <img
                src={`${item === 0 ? "/gold_call.png" : "/silver_call.png"}`}
                alt=""
                className="w-full"
              />
              <p className="ct-font-silk flex items-center justify-between p-2 text-xl">
                <span className={`${item === 0 && "ct-text-gt5"}`}>
                  {item === 0 && "CALL Genesis Epic"}
                  {item === 1 && "CALL Genesis Rare"}
                </span>
                <span className="h-16 w-16 overflow-hidden">
                  <img
                    src={`./i_${(item === 0 && "gold") || (item === 1 && "silver") || (item > 1 && "bronze")}.svg`}
                    alt=""
                    className="-ml-[10px] -mt-[2px] w-[84px] max-w-none"
                  />
                </span>
              </p>

              <div className="mb-3 flex items-center justify-between px-2">
                <div className="flex gap-1">
                  {/* <button
                    className="btn h-10 min-h-min"
                    onClick={() => {
                      if (item === 0) {
                        if (mintAmountA - 1 >= 0) {
                          setMintAmountA(mintAmountA - 1);
                        }
                      } else if (item === 1) {
                        if (mintAmountB - 1 >= 0) {
                          setMintAmountB(mintAmountB - 1);
                        }
                      }
                    }}
                  >
                    -
                  </button> */}
                  {/* <input
                    type="number"
                    pattern="\d*"
                    className="ct-num-input input h-10 w-16 pl-0 text-center text-white focus:outline-none"
                    value={item === 0 ? mintAmountA : mintAmountB}
                    onChange={(e) => {
                      const amount = parseInt(e.target.value);
                      item === 0
                        ? setMintAmountA(amount)
                        : setMintAmountA(amount);
                    }}
                  /> */}
                  {/* <button
                    className="btn h-10 min-h-min"
                    onClick={() => {
                      if (item === 0) {
                        setMintAmountA(mintAmountA + 1);
                      } else if (item === 1) {
                        setMintAmountB(mintAmountB + 1);
                      }
                      if (mintAmountA >= maxMintCount) {
                        setMintAmountA(maxMintCount)
                      }
                      if (mintAmountB >= maxMintCount) {
                        setMintAmountB(maxMintCount)
                      }
                    }}
                  >
                    +
                  </button> */}
                </div>
                <div className="flex w-full justify-between text-right text-sm">
                  {item === 0 && (
                    <div className="text-left text-base text-yellow-300">
                      <p>- 4,800,000 $CALL airdrop</p>
                      <p>- 60% trading reward bonus</p>
                    </div>
                  )}
                  {item === 1 && (
                    <div className="text-left text-base text-gray-500">
                      <p>- 2,100,000 $CALL airdrop</p>
                      <p>- 30% trading reward bonus</p>
                    </div>
                  )}

                  <div>
                    Total Minted{" "}
                    <span className="text-base text-white">
                      {item === 0 ? goldMinted : silverMinted}/
                      {item === 0 ? "333" : "1,111"}
                    </span>
                  </div>
                  {/* <div>
                    Ends in{" "}
                    <span className="text-base text-white">03 08 40 42</span>
                  </div> */}
                </div>
              </div>

              <div className="mb-3 flex justify-between px-2">
                <span>Price</span>
                <span>
                  {item === 0 && "1 SOL"}
                  {item === 1 && "0.5 SOL"}
                </span>
              </div>

              <button
                onClick={() => {
                  mint({
                    amount: item === 0 ? mintAmountA : mintAmountB,
                    mintType: item === 0 ? "gold" : "silver",
                  });
                }}
                className="btn h-10 min-h-min w-full rounded-none bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
              >
                {loadingMap[item === 0 ? "gold" : "silver"] ? (
                  <span className="loading loading-infinity loading-lg text-[#4c4c4c]"></span>
                ) : (
                  "Mint"
                )}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
