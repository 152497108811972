import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSolPrice } from "../context/solPriceContext";
import { useBuy } from "../hooks/useBuy";
import { useSell } from "../hooks/useSell";
import { toast } from "react-toastify";
import { getAssociatedTokenAddressSync } from "@solana/spl-token";
import API from "../API";
import {
  formatFromNowDateTime,
  formatUnit,
  formatUnitDecimal,
  num2str,
  cn,
} from "../lib/utils";
import { MEMEChart } from "../components/MEMEChart";
import { getPoolAddress, orderMints } from "../anchor/pda";
import {
  programId,
  tokenIssuer,
  cpSwapProgram,
  launchConfigAddress,
} from "../anchor/setup";
import useSwapRaydum from "../hooks/useSwapRaydium";

export default function TokensDetails() {
  const { address } = useParams();

  const { connection } = useConnection();

  const { publicKey } = useWallet();

  const solPrice = useSolPrice();

  const [tokenDetail, setTokenDetail] = useState({}); //
  const [tokenHolderInfoList, setTokenHolderInfoList] = useState([]); //
  const [solBalance, setSolBalance] = useState(0); // sol
  const [tokenBalance, setTokenBalance] = useState(""); // token

  const [buyAmount, setBuyAmount] = useState("");
  const [sellAmount, setSellAmount] = useState("");
  const [poolAdminPubKey, setpoolAdminPubKey] = useState("");
  const [tradeError, setTradeError] = useState("");
  const [buy, buyLoading] = useBuy();
  const [sell, sellLoading] = useSell();
  const { buyRaydium, sellRaydium } = useSwapRaydum();
  // const [poolId, setPoolId] = useState("");
  const [raydiumLoading, setRaydiumLoading] = useState(false);
  const buyAmountInputHandler = (value) => {
    if (isNaN(Number(value))) {
      return;
    }
    console.log(value, solBalance);
    setBuyAmount(value);
    if (solBalance && value > solBalance) {
      setTradeError(`Insufficient balance: You have ${solBalance} SOL`);
    } else {
      setTradeError("");
    }
  };
  const sellAmountInputHandler = (value) => {
    if (isNaN(Number(value))) {
      return;
    }
    console.log(value, tokenBalance);
    setSellAmount(value);
    if (Number(tokenBalance) && value > Number(tokenBalance)) {
      setTradeError(`Insufficient balance: You have ${tokenBalance}`);
    } else {
      setTradeError("");
    }
  };

  const getTokenDetail = async function () {
    let res = await API.meme.getTokenDetail({
      address,
    });
    API.r(res, async () => {
      if (res.data.tokenInfoResp.tokenStatus === "LAUNCHED") {
        /*
            raydium ,  poolId  iframe
            raydium ， raydium-sdk-v2
        */
        // let pid = await getPoolId(address);
        // const poolIdMapping = {
        //   CALLCAT: "ABKPS3q1e152JJ48eZcJSRZyXUGnJGoeAQ9Buuvr8FRb",
        //   CPEPE: "AetUAEuWnbTD4wXfoSbpotR1tbFgag3VvZDgkrTCL944",
        // };
        // const ticker = res.data.tokenInfoResp.ticker;
        // setPoolId(poolIdMapping[ticker] || pid);
      }
      setTokenDetail(res.data.tokenInfoResp);
      setTokenHolderInfoList(res.data.tokenHolderInfoRespList);
      setpoolAdminPubKey(res.data.tokenInfoResp.creatorAddress);
    });
  };

  useEffect(() => {
    if (address && publicKey) {
      // ，API
      // getBalance(new PublicKey(address)); //
      getTokenBalance();
      getSolBalance(); //sol
      // getTokenContractSolBalance();
    }
    getTokenDetail();
  }, [address, publicKey]);

  useEffect(() => {
    console.log("solPrice: ", solPrice);
  }, [solPrice]);

  const getSolBalance = async () => {
    if (!publicKey) {
      return;
    }
    try {
      const balance = await connection.getBalance(publicKey);
      setSolBalance(balance / LAMPORTS_PER_SOL);
      console.log("getSolBalance", balance);
    } catch (error) {
      console.log(error);
    }
  };

  const [tokenContractSolBalance, setTokenContractSolBalance] = useState(0);
  const getTokenContractSolBalance = async () => {
    console.log("in");

    let splTokenMint = new PublicKey(address);

    const pool = PublicKey.findProgramAddressSync(
      [
        new PublicKey(poolAdminPubKey).toBuffer(),
        splTokenMint.toBuffer(),
        Buffer.from("pool"),
      ],
      programId,
    )[0];
    console.log("pool", pool.toBase58());

    // const poolNativeAccount = PublicKey.findProgramAddressSync(
    //   [
    //     splTokenMint.toBuffer(),
    //     tokenIssuer.toBuffer(),
    //     Buffer.from("pool_native_account_seed"),
    //   ],
    //   programId,
    // )[0];
    // console.log("poolNativeAccount", poolNativeAccount.toBase58());

    // const poolTokenAccount = getAssociatedTokenAddressSync(
    //   splTokenMint,
    //   poolNativeAccount,
    //   true,
    // );
    // console.log("poolTokenAccount", poolTokenAccount.toBase58());

    const poolNativeAccount = PublicKey.findProgramAddressSync(
      [
        splTokenMint.toBuffer(),
        tokenIssuer.toBuffer(),
        Buffer.from("pool_native_account_seed"),
      ],
      programId,
    )[0];
    console.log("poolNativeAccount", poolNativeAccount.toBase58());
    const poolTokenAccount = getAssociatedTokenAddressSync(
      splTokenMint,
      poolNativeAccount,
      true,
    );
    console.log("poolTokenAccount", poolTokenAccount.toBase58());
    const solBalanceBeforePool = await connection.getBalance(poolNativeAccount);
    const tokenBalanceAfterPool = (
      await connection.getTokenAccountBalance(poolTokenAccount)
    ).value.uiAmount;

    console.log("solBalanceBeforePool", solBalanceBeforePool);
    console.log("tokenBalanceAfterPool", tokenBalanceAfterPool);

    const SOL = new PublicKey("So11111111111111111111111111111111111111112");
    const [mintA, mintB] = orderMints(SOL, splTokenMint);
    const [poolAddress] = await getPoolAddress(
      launchConfigAddress, // Config address,
      mintA,
      mintB,
      cpSwapProgram,
    );

    if (!poolAddress) {
      return;
    }
    console.log("poolAddress: ", poolAddress.toString());
    const balance = await connection.getBalance(poolAddress);
    setTokenContractSolBalance(balance / LAMPORTS_PER_SOL);
    console.log("tokenContractSolBalance", balance);
  };

  const getTokenBalance = async function () {
    if (!publicKey) {
      return;
    }
    let splToken = new PublicKey(address);
    const traderTokenAccount = getAssociatedTokenAddressSync(
      splToken,
      publicKey,
      true,
    );
    try {
      let t = await connection.getTokenAccountBalance(traderTokenAccount);
      console.log("getTokenBalance", t.value.uiAmount, t.value.uiAmountString);
      setTokenBalance(t.value.uiAmountString);
    } catch (error) {
      console.log(error);
      setTokenBalance("");
    }
  };

  async function getPoolId(address) {
    const SOL = new PublicKey("So11111111111111111111111111111111111111112");

    let splTokenMint = new PublicKey(address); // ,

    // const token0 = SOL > splTokenMint ? SOL : splTokenMint;
    // const token1 = SOL > splTokenMint ? splTokenMint : SOL;
    const [mintA, mintB] = orderMints(SOL, splTokenMint);

    const [poolAddress] = await getPoolAddress(
      launchConfigAddress, // Config address,
      mintA,
      mintB,
      cpSwapProgram,
    );

    console.log(`${address} --->`, poolAddress.toBase58());
    return poolAddress.toBase58();
  }

  //
  const [data, setData] = useState([]); //
  const [limit, setLimit] = useState(20); //
  const [offset, setOffset] = useState(0); //
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); //
  const pageTradeHistory = async function () {
    setLoading(true);
    let res = await API.meme.pageTradeHistory({
      address,
      page: {
        limit,
        offset,
      },
    });
    const newData = res.data.value;
    setData(newData);
    if (newData.length > 0) {
      if (newData.length < limit) {
        setHasMore(false); //
      }
    } else {
      setHasMore(false); //
    }
    setLoading(false);
  };
  useEffect(() => {
    pageTradeHistory();
  }, [limit, offset]);

  //
  let [interval, setChartInterval] = useState("1m"); // 1m 5m 1h

  //
  const placeTrade = async () => {
    if (isShow === 1) {
      if (buyLoading) {
        console.log("loading...");
        return;
      }
      let txBuy = await buy({
        amount: buyAmount,
        splToken: new PublicKey(address),
        poolAdminPubKey: new PublicKey(poolAdminPubKey),
      });
      if (txBuy) {
        toast.success(
          ({ closeToast }) => (
            <div>
              <h3 className="font-bold">Success!</h3>
              <a href={`https://solscan.io/tx/${txBuy}`} target="_blank">
                Click to view it
              </a>
            </div>
          ),
          {
            position: "top-right",
            autoClose: 6000,
            // autoClose: false,
            // hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      }
    }
    if (isShow === 2) {
      if (sellLoading) {
        console.log("loading...");
        return;
      }
      let txSell = await sell({
        amount: sellAmount,
        splToken: new PublicKey(address),
        poolAdminPubKey: new PublicKey(poolAdminPubKey),
      });

      if (txSell) {
        toast.success(
          ({ closeToast }) => (
            <div>
              <h3 className="font-bold">Success!</h3>
              <a href={`https://solscan.io/tx/${txSell}`} target="_blank">
                Click to view it
              </a>
            </div>
          ),
          {
            position: "top-right",
            autoClose: 6000,
            // autoClose: false,
            // hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      }
    }

    getTokenBalance();
  };

  //
  const placeTradeByraydium = async () => {
    if (raydiumLoading) {
      console.log("raydiumLoading...");
      return;
    }
    console.log("placeTradeByraydium");
    try {
      setRaydiumLoading(true);

      if (isShow === 1) {
        let txBuy = await buyRaydium(tokenDetail.poolId, buyAmount);
        if (txBuy) {
          toast.success(
            ({ closeToast }) => (
              <div>
                <h3 className="font-bold">Success!</h3>
                <a href={`https://solscan.io/tx/${txBuy}`} target="_blank">
                  Click to view it
                </a>
              </div>
            ),
            {
              position: "top-right",
              autoClose: 6000,
              // autoClose: false,
              // hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
        }
      }

      if (isShow === 2) {
        console.log("sellRaydium");
        let txSell = await sellRaydium(
          tokenDetail.poolId,
          new PublicKey(address),
          sellAmount,
        );

        if (txSell) {
          toast.success(
            ({ closeToast }) => (
              <div>
                <h3 className="font-bold">Success!</h3>
                <a href={`https://solscan.io/tx/${txSell}`} target="_blank">
                  Click to view it
                </a>
              </div>
            ),
            {
              position: "top-right",
              autoClose: 6000,
              // autoClose: false,
              // hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            },
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRaydiumLoading(false);
    }
  };

  const copyToClipboard = (str) => {
    const textarea = document.createElement("textarea");
    textarea.value = str;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  // Trades  &
  const [filter, setFilter] = useState(1),
    baseClass =
      "btn h-7 min-h-7 rounded-none border-none bg-transparent font-normal",
    bgColorMap = {
      0: "bg-[#DA88A1]",
      1: "bg-[#AEC7A9]",
      2: "bg-[#9991F3]",
      3: "bg-[#A8B789]",
      4: "bg-[#B0F9B2]",
    };

  /*
   **Buy & Sell tabs**
   *
   */
  const [isShow, setIsShow] = useState(1),
    baseTabClass = "btn h-10 min-h-min bg-gradient-to-r text-base font-normal",
    activeTab1Class = "from-[#00FF80] to-[#00FFE2] text-[#1B1D28]",
    normalTab1Class =
      "bg-[#1F2937] text-[#9CA3AF] hover:from-[#00FF80] hover:to-[#00FFE2] hover:text-[#1B1D28]",
    activeTab2Class = "from-[#FF1F0E] to-[#FF547F] text-white",
    normalTab2Class =
      "bg-[#1F2937] text-[#9CA3AF] hover:from-[#FF1F0E] hover:to-[#FF547F] hover:text-white";

  return (
    <div className="mx-auto grid max-w-[1600px] grid-cols-[1fr_350px] gap-8">
      {/* Left -Section */}
      <div>
        {/* info -Block */}
        <div className="mb-10 grid grid-cols-[1fr_auto]">
          <div className="grid grid-cols-[auto_1fr] gap-6">
            <div className="w-[100px]">
              <Link to="/" className="relative">
                <img
                  src={tokenDetail.image}
                  alt=""
                  className="h-[100px] w-full object-cover"
                />
                <img
                  src="/avatar_mask.png"
                  alt=""
                  className="absolute top-0 w-full"
                />
              </Link>
            </div>
            <div className="pt-4">
              <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                Created by
                <img
                  src={tokenDetail.avatar || "/logo.png"}
                  alt=""
                  className="h-4 w-4"
                />
                <Link to="" className="text-[#BFDBFE]">
                  {tokenDetail.creatorAddress
                    ? tokenDetail.creatorAddress.slice(0, 6)
                    : ""}
                </Link>
              </div>
              <p className="mb-4 text-xs text-[#28E1A6]">
                market cap:{" "}
                {solPrice == 0
                  ? `${formatUnitDecimal(Number(tokenDetail.currentMarketCap))} SOL`
                  : `$${(formatUnitDecimal(Number(tokenDetail.currentMarketCap)) * solPrice).toFixed(2)}`}
              </p>
              <p className="text-sm text-white">
                {tokenDetail.name} [ticker: {tokenDetail.ticker}]
              </p>
            </div>
          </div>

          <div className="flex gap-2 pt-4">
            {tokenDetail.twitterLink ? (
              <a
                href={tokenDetail.twitterLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn h-10 min-h-10 w-[230px] justify-start gap-0 rounded-lg border-2 border-white border-opacity-5 bg-[#191919] pl-4 text-sm font-normal text-[#A6A6A6]"
              >
                <img src="/i_x.svg" alt="" />
                X&#40;Twitter&#41;
              </a>
            ) : (
              ""
            )}

            {tokenDetail.telegramLink ? (
              <a
                href={tokenDetail.telegramLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn h-10 min-h-10 w-[230px] justify-start gap-0 rounded-lg border-2 border-white border-opacity-5 bg-[#191919] pl-4 text-sm font-normal text-[#A6A6A6]"
              >
                <img src="/i_telegram.svg" alt="" />
                Telegram
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mb-6 flex items-center gap-4 text-sm">
          <p>{tokenDetail.name}</p>
          <p>Ticker: {tokenDetail.ticker}</p>
          <p className="text-[#86EFAC]">
            Market cap: $
            {formatUnitDecimal(Number(tokenDetail.currentMarketCap * solPrice))}
          </p>
          <div className="flex">
            <p className="mr-2 flex items-center">CA:</p>
            <p className="flex h-[24px] items-center rounded-[4px_0_0_4px] border border-[#374151] px-[10px]">
              {address}
            </p>
            <p className="h-[24px] overflow-hidden rounded-[0_4px_4px_0] border border-l-0 border-[#374151]">
              <button
                className="btn h-[22px] min-h-[22px] rounded-none border-none bg-transparent font-normal"
                onClick={() => copyToClipboard(address)}
              >
                copy
              </button>
            </p>
          </div>
        </div>

        {/* chart -Block */}
        <div className="mb-6 min-h-[200px] bg-slate-600">
          {tokenDetail.tokenStatus === "LAUNCHED" ? (
            <iframe
              src={`https://www.geckoterminal.com/solana/pools/${tokenDetail.poolId}?embed=1&info=0&swaps=0&grayscale=1`}
              allowFullScreen
              className="h-[400px] w-full"
            />
          ) : (
            ""
          )}
          {tokenDetail.tokenStatus === "BOUNDING" ? (
            <MEMEChart address={address} interval={interval}></MEMEChart>
          ) : (
            ""
          )}
        </div>

        {/* Trades -Block */}
        <div className="mb-6 flex gap-2">
          <div className="flex items-center rounded-[4px] bg-[#00FF95] px-2 text-black">
            Trades
          </div>
          {tokenDetail.tokenStatus === "BOUNDING" ? (
            <div className="flex">
              <span
                className={`overflow-hidden rounded-[4px_0_0_4px] border border-[#374151] ${interval === "1m" ? "bg-[#26282c]" : ""}`}
              >
                <button
                  className={`${baseClass}`}
                  onClick={() => setChartInterval("1m")}
                >
                  1m
                </button>
              </span>
              <span
                className={`border border-l-0 border-[#374151] ${interval === "5m" ? "bg-[#26282c]" : ""}`}
              >
                <button
                  className={`${baseClass}`}
                  onClick={() => setChartInterval("5m")}
                >
                  5m
                </button>
              </span>
              <span
                className={`overflow-hidden rounded-[0_4px_4px_0] border border-l-0 border-[#374151] ${interval === "1h" ? "bg-[#26282c]" : ""}`}
              >
                <button
                  className={`${baseClass}`}
                  onClick={() => setChartInterval("1h")}
                >
                  1h
                </button>
              </span>
              {/* <span className="border border-l-0 border-[#374151]">
              <button
                className={`${baseClass} ${filter === 4 ? "bg-[#374151]" : ""}`}
                onClick={() => setFilter(4)}
              >
                6h
              </button>
            </span>
            <span className="overflow-hidden rounded-[0_4px_4px_0] border border-l-0 border-[#374151]">
              <button
                className={`${baseClass} ${filter === 5 ? "bg-[#374151]" : ""}`}
                onClick={() => setFilter(5)}
              >
                24h
              </button>
            </span> */}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* table -Block */}
        <div className="">
          <table className="ct-trades-table w-full border-separate border-spacing-y-2 text-center text-[#9EA2AE]">
            <thead>
              <tr className="h-11">
                <th className="pl-8 text-left">account</th>
                <th>type</th>
                <th>SOL</th>
                <th>{tokenDetail.ticker}</th>
                <th>date</th>
                <th className="pr-8 text-right">transaction</th>
              </tr>
            </thead>
            <tbody>
              {data.map((_, item) => (
                <tr key={item} className="h-11">
                  <td>
                    <div className="flex items-center gap-2 pl-8">
                      <img
                        src={_.avatar || "/logo.png"}
                        alt=""
                        className="h-5 w-5 rounded-full object-cover"
                      />
                      <Link
                        to=""
                        className={`rounded px-[4px] leading-5 text-black ${bgColorMap[item % 5]}`}
                      >
                        {_.accountAddress ? _.accountAddress.slice(0, 6) : ""}
                      </Link>
                    </div>
                  </td>
                  <td>
                    {_.type === "buy" ? (
                      <span className="text-[#00FF95]">buy</span>
                    ) : (
                      <span className="text-[#DA88A1]">sell</span>
                    )}
                  </td>
                  <td>{num2str(Number(_.sol))}</td>
                  <td>{formatUnitDecimal(Number(_.tokenNum))}</td>
                  <td>{formatFromNowDateTime(_.tradeTime)}</td>
                  <td className="pr-8 text-right">
                    <a
                      href={`https://explorer.solana.com/tx/${_.transactionId}`}
                    >
                      {_.transactionId ? _.transactionId.slice(0, 6) : ""}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex w-full justify-center">
            {/* <div className="justify-self-end"> */}
            <div className="flex justify-center space-x-2 text-slate-50">
              <button
                disabled={offset === 0}
                className={cn(
                  "text-sm text-slate-50 hover:bg-transparent hover:font-bold hover:text-slate-50",
                  {
                    "cursor-not-allowed text-sm text-slate-400 hover:text-slate-400":
                      offset === 0,
                  },
                )}
                onClick={() => {
                  setOffset((prev) => prev - limit);
                  setHasMore(true);
                }}
              >
                [ &lt;&lt; ]
              </button>
              <span>{offset / limit + 1}</span>
              <button
                onClick={() => {
                  setOffset((prev) => prev + limit);
                }}
                disabled={!hasMore}
                className={cn(
                  "text-sm text-slate-50 hover:bg-transparent hover:font-bold hover:text-slate-50",
                  {
                    "cursor-not-allowed text-sm text-slate-400 hover:text-slate-400":
                      !hasMore,
                  },
                )}
              >
                [ &gt;&gt; ]
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>

      {/* Right -Section */}
      <div className="pt-[140px]">
        {/* buy&sell -Block */}
        <div className="mb-7 rounded-lg bg-[#17181B] p-4">
          <div className="mb-8 grid grid-cols-2 gap-2">
            <button
              className={`${baseTabClass} ${isShow === 1 ? activeTab1Class : normalTab1Class}`}
              onClick={() => setIsShow(1)}
            >
              Buy
            </button>
            <button
              className={`${baseTabClass} ${isShow === 2 ? activeTab2Class : normalTab2Class}`}
              onClick={() => setIsShow(2)}
            >
              Sell
            </button>
          </div>
          {isShow === 1 && (
            <>
              {/* <div className="mb-4 flex justify-between">
                <span className="flex h-6 items-center rounded bg-[#1B1D28] px-2 text-xs text-[#9CA3AF]">
                  Switch to CCP
                </span>
                <span className="flex h-6 items-center rounded bg-[#1B1D28] px-2 text-xs text-[#9CA3AF]">
                  Set max slippage
                </span>
              </div> */}
              <div className="relative mb-2">
                <input
                  type="text"
                  value={buyAmount}
                  onChange={(e) => {
                    buyAmountInputHandler(e.target.value);
                  }}
                  placeholder="0.0"
                  className="input h-10 w-full rounded-md border-[#E2E8F0] bg-[#2E303A] pr-24 focus:border-[#E2E8F0]"
                />
                <div className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center gap-2 text-white">
                  SOL
                  <img src="/i_sol.png" alt="" className="w-8" />
                </div>
              </div>
              <div className="mb-4 flex h-8 items-center gap-1 rounded-lg bg-[#2E303A] px-1">
                <button
                  onClick={() => buyAmountInputHandler("")}
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  reset
                </button>
                <button
                  onClick={() => buyAmountInputHandler("1")}
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  1 SOL
                </button>
                <button
                  onClick={() => buyAmountInputHandler("5")}
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  5 SOL
                </button>
                <button
                  onClick={() => buyAmountInputHandler("10")}
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  10 SOL
                </button>
              </div>
              {tradeError ? (
                <div className="mb-3 text-sm text-red-400">{tradeError}</div>
              ) : (
                ""
              )}
              {parseFloat(buyAmount) > 0 ? (
                <div className="mb-3 text-sm text-gray-400">
                  You will get:{" "}
                  {`${num2str(
                    // ((1000000000 - parseFloat(tokenDetail.grantedTokenNum) - 30*1e9 / (parseFloat(tokenDetail.crowdfundingAmount) + 30 + parseFloat(buyAmount)))).toFixed(2)
                    (
                      (parseFloat(buyAmount) *
                        0.99 *
                        (1000000000 -
                          parseFloat(tokenDetail.grantedTokenNum))) /
                      (30 +
                        parseFloat(tokenDetail.crowdfundingAmount) +
                        parseFloat(buyAmount) * 0.99)
                    ).toFixed(2),
                  )} $${tokenDetail.ticker}`}
                </div>
              ) : (
                ""
              )}
              <button
                onClick={() => {
                  if (!publicKey) {
                    toast.error(
                      ({ closeToast }) => (
                        <div>
                          <h3 className="font-bold">wallet not connected</h3>
                          <p>please connect your wallet to place a trade.</p>
                        </div>
                      ),
                      {
                        position: "top-right",
                        autoClose: 6000,
                        // autoClose: false,
                        // hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      },
                    );
                    return;
                  }
                  if (tokenDetail.tokenStatus === "LAUNCHING") return;
                  tokenDetail.tokenStatus === "LAUNCHED"
                    ? placeTradeByraydium() //
                    : placeTrade();
                }}
                className="btn h-10 min-h-min w-full bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base font-normal text-[#1B1D28]"
              >
                {sellLoading ||
                raydiumLoading ||
                tokenDetail.tokenStatus === "LAUNCHING" ? (
                  <span className="loading loading-infinity loading-lg text-[#4c4c4c]">
                    {tokenDetail.tokenStatus === "LAUNCHING" ? "LAUNCHING" : ""}
                  </span>
                ) : (
                  "place trade"
                )}
              </button>
            </>
          )}
          {isShow === 2 && (
            <>
              {/* <div className="mb-4 flex justify-between">
                <span></span>
                <span className="flex h-6 items-center rounded bg-[#1B1D28] px-2 text-xs text-[#9CA3AF]">
                  Set max slippage
                </span>
              </div> */}
              <div className="relative mb-2">
                <input
                  type="text"
                  value={sellAmount}
                  onChange={(e) => {
                    sellAmountInputHandler(e.target.value);
                  }}
                  placeholder="0.0"
                  className="input h-10 w-full rounded-md border-[#E2E8F0] bg-[#2E303A] pr-40 focus:border-[#E2E8F0]"
                />
                <div className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center gap-2 text-white">
                  {tokenDetail.name}
                  <img
                    src={tokenDetail.image}
                    alt=""
                    className="h-8 w-8 object-cover"
                  />
                </div>
              </div>
              <div className="mb-4 flex h-8 items-center gap-1 rounded-lg bg-[#2E303A] px-1">
                <button
                  onClick={() => sellAmountInputHandler("")}
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  reset
                </button>
                <button
                  onClick={() =>
                    sellAmountInputHandler(Math.floor(tokenBalance * 0.25))
                  }
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  25%
                </button>
                <button
                  onClick={() =>
                    sellAmountInputHandler(Math.floor(tokenBalance * 0.5))
                  }
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  50%
                </button>
                <button
                  onClick={() =>
                    sellAmountInputHandler(Math.floor(tokenBalance * 0.75))
                  }
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  75%
                </button>
                <button
                  onClick={() => sellAmountInputHandler(tokenBalance)}
                  className="btn h-6 min-h-6 rounded px-2 text-xs font-normal"
                >
                  100%
                </button>
              </div>
              {tradeError ? (
                <div className="mb-3 text-sm text-red-400">{tradeError}</div>
              ) : (
                ""
              )}
              {parseFloat(sellAmount) > 0 ? (
                <div className="mb-3 text-sm text-gray-400">
                  You will get:{" "}
                  {`${num2str(
                    // -(30*1e9 / ((1000000000 - parseFloat(tokenDetail.grantedTokenNum)) + parseFloat(sellAmount)) - parseFloat(tokenDetail.crowdfundingAmount) - 30).toFixed(2)
                    (
                      ((parseFloat(sellAmount) *
                        (30 + parseFloat(tokenDetail.crowdfundingAmount))) /
                        (1000000000 -
                          parseFloat(tokenDetail.grantedTokenNum) +
                          parseFloat(sellAmount))) *
                      0.99
                    ).toFixed(2),
                  )} SOL`}
                </div>
              ) : (
                ""
              )}
              <button
                onClick={() => {
                  if (!publicKey) {
                    toast.error(
                      ({ closeToast }) => (
                        <div>
                          <h3 className="font-bold">wallet not connected</h3>
                          <p>please connect your wallet to place a trade.</p>
                        </div>
                      ),
                      {
                        position: "top-right",
                        autoClose: 6000,
                        // autoClose: false,
                        // hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      },
                    );
                    return;
                  }
                  if (tokenDetail.tokenStatus === "LAUNCHING") return;
                  tokenDetail.tokenStatus === "LAUNCHED"
                    ? placeTradeByraydium() //
                    : placeTrade();
                }}
                className="btn h-10 min-h-min w-full bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base font-normal text-[#1B1D28]"
              >
                {sellLoading ||
                raydiumLoading ||
                tokenDetail.tokenStatus === "LAUNCHING" ? (
                  <span className="loading loading-infinity loading-lg text-[#4c4c4c]">
                    {tokenDetail.tokenStatus === "LAUNCHING" ? "LAUNCHING" : ""}
                  </span>
                ) : (
                  "place trade"
                )}
              </button>
            </>
          )}
        </div>

        {(tokenDetail.canProposal || tokenDetail.canVote) && (
          <div className="mb-7 rounded-lg bg-[#17181B] p-4">
            <button
              onClick={() => {
                if (tokenDetail.canProposal) {
                  window.location.href = "/ctocreate";
                }
                if (tokenDetail.canVote) {
                  window.location.href = `/ctovote/${tokenDetail.address}`;
                }
              }}
              className="btn mx-auto block h-10 min-h-min w-4/5 bg-gradient-to-r from-[#ff5f00] to-[#ffaa00] text-base font-normal text-[#1B1D28]"
            >
              {tokenDetail.canProposal ? "Nominate" : "Vote"}
            </button>
          </div>
        )}

        {/* tokenHolderInfoList -Block */}
        <div className="rounded-lg bg-[#17181B] p-4">
          <div className="mb-7 text-xs">
            <p>bonding curve progress:{tokenDetail.crowdfundingRate}%</p>
            <progress
              className="progress progress-success mb-6 mt-1.5 h-2.5 w-full"
              value={tokenDetail.crowdfundingRate}
              max="100"
            ></progress>
            <p className="mb-5">
              when the market cap reaches ${(85 * solPrice).toFixed(2)} all the
              liquidity from the bonding curve will be deposited into Raydium
              and burned. progression increases as the price goes up.
            </p>
            <p>
              there are {1000000000 - parseInt(tokenDetail.grantedTokenNum)}{" "}
              tokens still available for sale in the bonding curve and there is{" "}
              {Number(tokenDetail.crowdfundingAmount).toFixed(4)} SOL in the
              bonding curve.
            </p>
          </div>

          {/* <div className="mb-7">
            <p className="text-xs">king of the hill progress: 40%</p>
            <progress
              class="progress progress-warning mt-1.5 h-2.5 w-full"
              value="40"
              max="100"
            ></progress>

            <div class="divider">OR</div>

            <div className="font-bold text-[#FFE24D]">
              👑 Crowned king of the hill on 2024/7/31 00:46:23
            </div>
          </div> */}

          <div className="">
            <h3 className="font-bold">Holder distribution</h3>
            <ol className="text-xs">
              {tokenHolderInfoList.map((_, item) => (
                <li className="mt-2 flex justify-between" key={item}>
                  <span className="flex gap-1">
                    <span className="w-4 text-right">{item + 1}.</span>
                    <span className="h-4 w-4">
                      <img
                        src={_.avatar ? _.avatar : "/logo.png"}
                        alt=""
                        className="h-4 rounded-full object-cover"
                      />
                    </span>
                    {_.nickName
                      ? _.nickName
                      : `${_.address.slice(0, 3)}...${_.address.slice(41, 44)}`}
                    {_.isCreator && (
                      <span className="flex items-center rounded-sm bg-[#DA88A1] px-1 leading-none text-black">
                        dev
                      </span>
                    )}
                  </span>
                  <span>{(_.holdRate * 100).toFixed(2)}%</span>
                </li>
              ))}
            </ol>
          </div>

          {/* {tokenHolderInfoList.map((_, item) => (
            <div key={_.address} className="mt-7 grid gap-5">
              <div className="break-all">
                address:
                <br />
                {_.address}
              </div>
              <div className="">
                avatar:
                <img src={_.avatar} alt="" className="h-40 w-40 object-cover" />
              </div>
              <div>
                holdRate:
                <br />
                {_.holdRate}
              </div>
              <div>
                nickName:
                <br />
                {_.nickName}
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
}
