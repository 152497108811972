import BN from "bn.js";
import {
  ApiV3PoolInfoStandardItemCpmm,
  CpmmKeys,
  CpmmRpcData,
  CurveCalculator,
  Cluster,
  Raydium,
} from "@raydium-io/raydium-sdk-v2";

import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { NATIVE_MINT } from "@solana/spl-token";

import { RPC } from "../anchor/setup";

import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from "@solana/wallet-adapter-react";
import { AnchorProvider } from "@coral-xyz/anchor";
import { convertToLamports } from "../lib/utils";

export default function useSwapRaydum() {
  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  const { publicKey, signTransaction } = useWallet();

  async function getProvider(rpc: string, wallet: any) {
    return new AnchorProvider(connection, wallet, {
      skipPreflight: true,
    });
  }

  const initSdk = async (params?: { loadToken?: boolean }) => {
    if (!publicKey || !connection) return;

    let cluster: Cluster = "mainnet";

    const raydium = await Raydium.load({
      owner: publicKey,
      connection: connection as any,
      cluster,
      disableFeatureCheck: true,
      disableLoadToken: !params?.loadToken,
      blockhashCommitment: "finalized",
    });

    console.log(raydium);
    return raydium;
  };

  // 共用的获取池信息和执行交换的逻辑
  const executeSwap = async (
    poolId: string,
    inputMint: string,
    inputAmount: BN,
    computeBudgetConfig?: {
      units: number;
      microLamports: number;
    },
  ) => {
    if (!publicKey || !wallet || !signTransaction) return;

    const raydium = await initSdk();
    if (!raydium) return;

    if (raydium.cluster === "mainnet") {
      const provider = await getProvider(RPC, wallet);
      const data = await raydium.cpmm.getPoolInfoFromRpc(poolId);

      const poolInfo: ApiV3PoolInfoStandardItemCpmm = data.poolInfo;
      const poolKeys: CpmmKeys | undefined = data.poolKeys;
      const rpcData: CpmmRpcData = data.rpcData;

      if (
        inputMint !== poolInfo.mintA.address &&
        inputMint !== poolInfo.mintB.address
      ) {
        throw new Error("input mint does not match pool");
      }

      const baseIn = inputMint === poolInfo.mintA.address;
      const swapResult = CurveCalculator.swap(
        inputAmount,
        baseIn ? rpcData.baseReserve : rpcData.quoteReserve,
        baseIn ? rpcData.quoteReserve : rpcData.baseReserve,
        rpcData.configInfo!.tradeFeeRate,
      );

      const { transaction } = await raydium.cpmm.swap({
        poolInfo,
        poolKeys,
        inputAmount,
        swapResult,
        slippage: 0.001,
        baseIn,
        computeBudgetConfig,
      });

      try {
        const tx = await provider.sendAndConfirm(transaction);
        console.log(tx);
        return tx;
      } catch (error) {
        console.log("provider.sendAndConfirm", error);
      }
    }
  };

  const swap_buy_raydium = async (poolId: string, amountIn: string) => {
    console.log("swap_buy_raydium", NATIVE_MINT);
    return executeSwap(
      poolId,
      NATIVE_MINT.toBase58(),
      new BN(Number(amountIn) * LAMPORTS_PER_SOL),
    );
  };

  const swap_sell_raydium = async (
    poolId: string,
    coinMint: PublicKey,
    inputAmount: string,
  ) => {
    return executeSwap(
      poolId,
      coinMint.toBase58(),
      convertToLamports(inputAmount),
      {
        units: 600000,
        microLamports: 10000000,
      },
    );
  };

  return {
    buyRaydium: swap_buy_raydium,
    sellRaydium: swap_sell_raydium,
  };
}
