import * as anchor from "@coral-xyz/anchor";
import {
  useConnection,
  useWallet,
  useAnchorWallet,
} from "@solana/wallet-adapter-react";

import { Program, Idl, AnchorProvider } from "@coral-xyz/anchor";
// import { Program, Provider, AnchorProvider } from '@project-serum/anchor';
import { BN } from "bn.js";
// import { expect } from 'chai';
// import idl from '/Users/shiyimin/workspace/blockchain/pump/pumpfun/target/idl/pumpfun.json'
// import type { Pumpfun } from '../target/types/pumpfun';
// import { PROGRAM_ID, IDL } from "../target/types/pumpfun";
// import IDL from '/Users/shiyimin/workspace/blockchain/pump/pumpfun/target/idl/pumpfun.json';
import IDL from "../anchor/pumpfun.json";
import {
  // Connection,
  Keypair,
  // PublicKey,
  SystemProgram,
  LAMPORTS_PER_SOL,
  type Signer,
  ComputeBudgetProgram,
} from "@solana/web3.js";
import NodeWallet from "@coral-xyz/anchor/dist/cjs/nodewallet";
import {
  createMint,
  getMint,
  createAssociatedTokenAccount,
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import React, { useState, useEffect, useRef } from "react";

import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { arrayBufferToBase64, formatDate, uuid } from "../lib/utils";
import { toast } from "react-toastify";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import API from "../API";
import { putFile } from "../lib/upload";
import { programId, tokenIssuer, RPC, dev, vault } from "../anchor/setup";

const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s",
);
global.Buffer = require("buffer").Buffer;

const Create = () => {
  const [name, setName] = useState("");
  const [ticker, setTicker] = useState("");
  const [description, setDescription] = useState("");
  // const [imageBase64, setImageBase64] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const imageFileRef = useRef<HTMLInputElement>(null);
  const [twitter, setTwitter] = useState("");
  const [telegram, setTelegram] = useState("");
  const [website, setWebsite] = useState("");
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);

  const { signTransaction, publicKey } = useWallet();
  const wallet = useAnchorWallet();
  const { connection } = useConnection();
  const [isLoading, setIsLoading] = useState(false);

  async function getProvider(rpc: string, wallet: any) {
    /* create the provider and return it to the caller */
    /* network set to local network for now */
    // const network = rpc;
    // const connection = new Connection(network, "processed");

    const provider = new AnchorProvider(connection, wallet, {
      skipPreflight: true,
    });
    return provider;
  }

  const navigate = useNavigate();

  // const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (!event?.target?.files?.length) {
  //     return;
  //   }
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setImageBase64(reader.result as string);
  //   };
  //   reader.readAsDataURL(file);
  // };
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files[0]);
    }
  };
  const validateInputs = () => {
    if (!name) throw new Error("Coin needs a name");
    if (name.length > 32)
      throw new Error("Name too long: it must be less than 32 characters");
    if (!ticker) throw new Error("Coin needs a ticker");
    if (ticker.length > 10)
      throw new Error("Ticker cannot be more than 10 characters");
    if (!imageFile) throw new Error("No image uploaded");
    if (description && description.length > 2000)
      throw new Error("Description too long");

    if (imageFile && imageFile.size > 4.5 * 1024 * 1024)
      throw new Error("Image too large: it must be less than 4.5 megabytes");
  };

  const submitCreateCoin = async () => {
    setIsCreating(true);
    setError(null);

    try {
      validateInputs();

      // console.log(res);
      // debugger;
      let imgFileName = `${publicKey?.toBase58()}/${formatDate(
        new Date().getTime(),
        "yyyyMMdd",
      )}/createcoin/${uuid("image")}.jpg`;

      type fileItem = {
        fileName: string;
        file: File | null;
      };
      interface UploadResponse {
        cdnUrl?: string;
      }

      const putFilePromise = (options: {
        fileList: Array<fileItem>;
        onSuccess?: Function;
        purpose: string;
      }): Promise<UploadResponse> => {
        return new Promise((resolve, reject) => {
          options.onSuccess = (res: UploadResponse) => {
            resolve(res);
          };
          try {
            putFile(options);
          } catch (error) {
            reject(error);
          }
        });
      };

      let uploadImage;
      try {
        uploadImage = await putFilePromise({
          fileList: [{ fileName: imgFileName, file: imageFile }],
          purpose: "meme.applyCreateToken",
        });
      } catch (error) {
        console.error(error);
      }

      let coinImageUrl = uploadImage?.cdnUrl;
      const blob = new Blob(
        [
          JSON.stringify(
            {
              name: name,
              symbol: ticker,
              description: description,
              image: coinImageUrl,
            },
            null,
            2,
          ),
        ],
        { type: "application/json" },
      );

      let metadataUriFileName = `${publicKey?.toBase58()}/${formatDate(
        new Date().getTime(),
        "yyyyMMdd",
      )}/createcoin/${uuid("metadata")}.JSON`;

      const metadataUriFile = new File([blob], "metadata.json", {
        type: "application/json",
      });

      let uploadmetadataUri = await putFilePromise({
        fileList: [{ fileName: metadataUriFileName, file: metadataUriFile }],
        purpose: "meme.applyCreateToken",
      });

      const metadata = {
        name: name,
        symbol: ticker,
        uri: uploadmetadataUri.cdnUrl,
      };

      console.log(metadata);

      let createTokenReq = {
        name: name,
        ticker: ticker,
        description: description,
        image: coinImageUrl,
        twitterLink: twitter,
        telegramLink: telegram,
        website: website,
        address: "",
      };

      // create coin
      // compose metaData
      // const metadataDemo = {
      //   name: name,
      //   symbol: ticker,
      //   uri: "https://raw.githubusercontent.com/solana-developers/program-examples/new-examples/tokens/tokens/.assets/nft.json",
      //  };
      // const createTokenReqDemo = {
      //   name: name,
      //   ticker: ticker,
      //   description: description,
      //   image:
      //     "https://static.onecms.io/wp-content/uploads/sites/6/2018/08/simp_homersingle08_f_hires2-2000.jpg",
      //   twitterLink: twitter,
      //   telegramLink: telegram,
      //   website: website,
      //   address: "",
      // };

      let idRes = await API.meme.getId();
      console.log(idRes.data.value);
      // createCoinOnChain(pool_id, metadataDemo, createTokenReqDemo);

      await createCoinOnChain(idRes.data.value + "", metadata, createTokenReq);

      // toast(
      //   ({ closeToast }) => (
      //     <div>
      //       <h3 className="font-bold">Created new coin</h3>
      //       <p>Click to view it</p>
      //       <a href={`/${publicKey ? publicKey.toBase58() : ""}`}>
      //         <button onClick={closeToast} className="mt-2">
      //           View
      //         </button>
      //       </a>
      //     </div>
      //   ),
      //   {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   },
      // );
    } catch (err: any) {
      console.error("Could not create coin", err);
      setError(err.message);
    } finally {
      setIsCreating(false);
    }
  };

  const wait = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const createCoinOnChain = async (
    pool_id: string,
    metadata: any,
    createTokenReq: any,
  ) => {
    console.log(pool_id);

    if (!publicKey) {
      return;
    }
    const supply = new anchor.BN(1_000_000_000);
    const provider = await getProvider(RPC, wallet as NodeWallet);
    // debugger;
    const connection = provider.connection;
    anchor.setProvider(provider);
    // const signer = provider.wallet as anchor.Wallet;
    // const program = anchor.workspace.Pumpfun as Program<Pumpfun>;
    const program = new anchor.Program(IDL as Idl, programId, provider);

    const splTokenMint = PublicKey.findProgramAddressSync(
      [Buffer.from(pool_id), Buffer.from("create_token_pool")],
      programId,
    )[0];
    console.log(`token address ${splTokenMint}`);
    // debugger;
    const poolPair = PublicKey.findProgramAddressSync(
      [publicKey.toBuffer(), splTokenMint.toBuffer(), Buffer.from("pool")],
      programId,
    );
    const metadataAccount = PublicKey.findProgramAddressSync(
      [
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        splTokenMint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID,
      // programID
    )[0];

    const pool = poolPair[0];

    const poolNativeAccount = PublicKey.findProgramAddressSync(
      [
        splTokenMint.toBuffer(),
        tokenIssuer.toBuffer(),
        Buffer.from("pool_native_account_seed"),
      ],
      programId,
    )[0];

    const poolTokenAccount = getAssociatedTokenAddressSync(
      splTokenMint,
      poolNativeAccount,
      true,
    );

    let vaultFeeInfo;
    const vaultFeeInfoKey = PublicKey.findProgramAddressSync(
      [Buffer.from("initialize")],
      programId,
    )[0];

    try {
      vaultFeeInfo = await program.account.vaultFeeInfo.fetch(vaultFeeInfoKey);
    } catch {}

    // if (!vaultFeeInfo) {
    //   let tx = await program.methods
    //   .initialize(vault, dev, 400, 100, new BN(LAUNCH_THRESHOLD).mul(new BN(LAMPORTS_PER_SOL)))
    //   .accounts({
    //     payer: token_issuer.publicKey,
    //     vaultFeeInfo: vaultFeeInfoKey,
    //     systemProgram: SystemProgram.programId,
    //   })
    //   .transaction();

    //   await provider.sendAndConfirm(tx);
    //   console.log(`tx: ${tx.signature}`);
    // }

    let mintAccount = null;

    try {
      mintAccount = await getMint(connection, splTokenMint);
    } catch {}

    if (!mintAccount) {
      let account_list = {
        payer: publicKey,
        metadataAccount: metadataAccount,
        poolNativeAccount: poolNativeAccount,
        splTokenMint: splTokenMint,
        pool: pool,
        vaultFeeInfo: vaultFeeInfoKey,
        poolTokenAccount: poolTokenAccount,
        systemProgram: SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
      };
      const transaction = await program.methods
        .createTokenPool(
          pool_id,
          metadata.name,
          metadata.symbol,
          metadata.uri,
          supply,
        )
        .preInstructions([
          ComputeBudgetProgram.setComputeUnitLimit({ units: 400000 }),
        ])
        .accounts(account_list)
        .transaction();

      // let tx = await provider.sendAndConfirm(transaction);
      // console.log("Success!", tx);

      if (imageFileRef.current) {
        imageFileRef.current.value = "";
      }

      let res = await API.meme.applyCreateToken({
        createTokenReq: JSON.stringify({
          ...createTokenReq,
          address: splTokenMint,
        }),
      });

      API.r(res, () => {
        console.log("create success");
      });

      await wait(3000);

      let tx = await provider.sendAndConfirm(transaction);
      console.log("Success!", tx);

      // console.log(1);
      // await wait(15000);
      // console.log(2);

      // debugger;
      toast.success(
        ({ closeToast }) => (
          <div>
            <a href={`/tokensdetails/${splTokenMint}`}>
              <h3 className="font-bold">Created new coin</h3>
              Click to view it
            </a>
          </div>
        ),
        {
          position: "top-right",
          // autoClose: 5000,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );

    } else {
      console.log(
        `mintAuthority: ${mintAccount.mintAuthority}, address: ${mintAccount.address}, freezeAuthority: ${mintAccount.freezeAuthority}`,
      );

      let tokenAmountInPool =
        await connection.getTokenAccountBalance(poolTokenAccount);
      console.log(`tokenAmountInPool: ${tokenAmountInPool.value.uiAmount}`);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative max-w-[420px] rounded-lg p-6 text-white">
        <img
          onClick={() => {
            navigate(-1);
          }}
          src="./go_back.svg"
          alt=""
          className="absolute left-[-67px] top-[48px] cursor-pointer"
        />
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="mb-1 text-sm font-semibold text-[#0AD1B9]"
            >
              Name*
            </label>
            <input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="rounded-md border border-[#666] bg-[#17181B] p-2"
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="ticker"
              className="mb-1 text-sm font-semibold text-[#0AD1B9]"
            >
              Ticker*
            </label>
            <input
              id="ticker"
              value={ticker}
              onChange={(e) => setTicker(e.target.value)}
              className="rounded-md border border-[#666] bg-[#17181B] p-2"
            />
            {ticker && ticker.length > 10 && (
              <p className="text-red-500">
                Ticker cannot be more than 10 characters
              </p>
            )}
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="description"
              className="mb-1 text-sm font-semibold text-[#0AD1B9]"
            >
              Description*
            </label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="h-24 rounded-md border border-[#666] bg-[#17181B] p-2"
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor="image"
              className="mb-1 text-sm font-semibold text-[#0AD1B9]"
            >
              Image*
            </label>
            <input
              id="image"
              type="file"
              ref={imageFileRef}
              accept="image/*"
              onChange={handleImageUpload}
              className="w-full rounded-md border border-[#666] bg-[#17181B] p-2"
            />
            {/* {imageBase64 && (
              <img
                src={imageBase64}
                alt="Uploaded image"
                className="mt-2 h-auto max-w-full"
              />
            )} */}
          </div>

          {/* <div
            onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
            className="w-fit cursor-pointer text-[#0AD1B9] hover:underline"
          >
            {showAdvancedOptions
              ? "Hide more options ↑"
              : "Show more options ↓"}
          </div> */}

          {showAdvancedOptions && (
            <>
              <div className="flex flex-col">
                <label
                  htmlFor="twitter"
                  className="mb-1 text-sm font-semibold text-[#0AD1B9]"
                >
                  Twitter Link
                </label>
                <input
                  id="twitter"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                  placeholder="Optional"
                  className="rounded-md border border-[#666] bg-[#17181B] p-2"
                />
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="telegram"
                  className="mb-1 text-sm font-semibold text-[#0AD1B9]"
                >
                  Telegram Link
                </label>
                <input
                  id="telegram"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                  placeholder="Optional"
                  className="rounded-md border border-[#666] bg-[#17181B] p-2"
                />
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="website"
                  className="mb-1 text-sm font-semibold text-[#0AD1B9]"
                >
                  Website Link
                </label>
                <input
                  id="website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  placeholder="Optional"
                  className="rounded-md border border-[#666] bg-[#17181B] p-2"
                />
              </div>
            </>
          )}

          <p className="text-xs">
            Tips: Coin data cannot be changed after creation
          </p>

          <Button onClick={submitCreateCoin} disabled={isCreating}>
            {isCreating ? (
              <span className="loading loading-infinity loading-lg text-[#4c4c4c]"></span>
            ) : (
              "place trade"
            )}
          </Button>

          {error && <p className="text-red-500">{error}</p>}

          <p className="text-xs">Cost to deploy:~0.02 SOL</p>
        </div>
      </div>
    </div>
  );
};

export default Create;
